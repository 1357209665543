import { useContext, useRef, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  DialogContentText,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import { useOktaAuth } from '@okta/okta-react';
import MapSecteurChargeDev from '../components/map/mapSecteurChargeDev';
import { AppConfigContext } from '../context';

import _ from 'lodash';
import SnackbarFeedback from '../components/SnackbarFeedback';
import BackendApiService from '../service/BackendApiService';
import { buildAllSecteursCd, getCurrentAffectationsDuCd } from '../service/regions_foncia';
import RemplacerCdDialog from './RemplacerCdDialog';
import useUserInfo from '../hooks/useUserInfo';
import { useEffect } from 'react';
import { ADMIN, GESTIONNAIRE_REGION } from '../Constantes';

import RemplacerManagerDialog from './RemplacerManagerDialog';
import ModalComponent from './ModalComponent';

function SecteurChargeDev() {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();

  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedSociete, setSelectedSociete] = useState(null);
  const [metier, setMetier] = useState();
  const [societes, setSocietes] = useState([]);

  const [selectedChargeDev, setSelectedChargeDev] = useState(null);
  const [chargeDevList, setChargeDevList] = useState([]);

  const [affectationMode, setAffectationMode] = useState(false);
  const [currentAffectations, setCurrentAffectaions] = useState([]);
  const [selectedAffectationZones, setSelectedAffectationZones] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [canUpdateCD, setCanUpdateCD] = useState([]);

  const [showDialogAddCD, setShowDialogAddCD] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const { userInfo, userInfoLoading } = useUserInfo();

  const mapRef = useRef();

  const service = new BackendApiService(appConfig.app.backendApiUrl, authState);

  useEffect(() => {
    setLoading(userInfoLoading);
  }, [userInfoLoading]);

  useEffect(() => {
    const profil = userInfo?.profil;
    const refCabinetAccess = userInfo?.access.reduce((acc, val) => [...acc, val.refCabinet], []);

    const isAuthorizedToUpdateCD = profil === ADMIN || (profil === GESTIONNAIRE_REGION && refCabinetAccess?.includes(selectedSociete?.refCabinet));
    setCanUpdateCD(isAuthorizedToUpdateCD);
  }, [selectedSociete, userInfo]);

  const showSocietesByRegion = async function (nomRegion) {
    setLoading(true);

    try {
      const res = await service.fetchSocietes(nomRegion);

      if (!res.ok) {
        const errorStatus = res.status;
        const errorText = await res.text();
        throw new Error(errorStatus + ' ' + errorText);
      }

      const data = await res.json();
      const filteredData = data.filter((etab) => etab.latitude && etab.longitude);
      setSocietes(filteredData);
      mapRef.current.showSocietes(filteredData);

      if (selectedSociete) {
        setSelectedSociete(
          _.find(filteredData, {
            refSiFinance: selectedSociete.refSiFinance,
          })
        );
      }
    } catch (error) {
      handleOpenSnackbar({
        severity: 'error',
        msg: 'Erreur survenue: ' + error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectRegion = async (region) => {
    setSelectedRegion(region);
    await showSocietesByRegion(region.nom);
  };

  const handleSelectSociete = function (societe) {
    setSelectedSociete(societe);
    setChargeDevList([]);
    setMetier('');
  };

  const onSelectedCommune = (zone, codeCommune) => {
    const found = selectedAffectationZones.find((item) => item.code === codeCommune);
    if (!found) {
      setSelectedAffectationZones([...selectedAffectationZones, zone]);
    } else {
      const newAffectationsZones = selectedAffectationZones.filter((item) => item.code !== codeCommune);
      setSelectedAffectationZones(newAffectationsZones);
    }
  };

  const handleSelectCommune = function (commune) {
    let aff = { ...commune };
    aff.refIndividu = selectedChargeDev.idIndividu;

    onSelectedCommune(aff, commune.code);
    setCurrentAffectaions([...currentAffectations, { ...commune }]);
  };

  const handleRemoveCommune = function (codeCommune) {
    let affExistanteArr = _.remove(currentAffectations, {
      code: codeCommune,
    });
    if (affExistanteArr.length > 0) {
      let aff = affExistanteArr[0];
      delete aff.refIndividu;

      onSelectedCommune(aff, codeCommune);
    }
  };

  const handleSelectChargeDev = function (cd) {
    setSelectedChargeDev(cd);
    setAffectationMode(true);

    let allSecteursCd = buildAllSecteursCd(selectedSociete, metier, chargeDevList);

    let currentAffectationsDuCd = getCurrentAffectationsDuCd(selectedSociete, metier, cd);
    setCurrentAffectaions(_.cloneDeep(currentAffectationsDuCd));

    mapRef.current.enableAffectationMode(currentAffectationsDuCd, metier, allSecteursCd);
  };

  const disableAffectationMode = function () {
    setAffectationMode(false);
    setSelectedChargeDev(null);

    mapRef.current.disableAffectationMode();
  };

  const showChargeDevList = async (metier) => {
    setMetier(metier);
    setLoading(true);

    try {
      const res = await service.fetchChargeDevList(selectedSociete, metier);

      if (!res.ok) {
        const errorStatus = res.status;
        const errorText = await res.text();
        throw new Error(errorStatus + ' ' + errorText);
      }

      const data = await res.json();

      setChargeDevList(data);

      if (_.isEmpty(data)) {
        handleOpenSnackbar({
          severity: 'warning',
          msg: 'Aucun chargé de dev trouvé pour le métier ' + metier,
        });
      }
    } catch (error) {
      handleOpenSnackbar({
        severity: 'error',
        msg: 'Erreur survenue: ' + error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSauvegarder = async function (e) {
    if (selectedAffectationZones.length) {
      setLoading(true);
      try {
        const results = await service.saveAffectationsChargeDev(selectedSociete, selectedAffectationZones, metier);

        let res;
        results.forEach((r) => {
          res = r;
          if (r.status === 'rejected') {
            handleOpenSnackbar({
              severity: 'error',
              msg: 'Au moins une sauvegarde rejetée',
            });
            return;
          }
        });

        const result = res.value ? res.value : res.reason;

        if (!result.ok) {
          const errorStatus = result.status;
          const errorText = await result.text();
          throw new Error(errorStatus + ' ' + errorText);
        }
        setSelectedAffectationZones([]);
        await showSocietesByRegion(selectedRegion.nom);
        handleOpenSnackbar({
          severity: 'success',
          msg: 'Sauvegarde OK',
        });

        disableAffectationMode();
      } catch (error) {
        setFeedback({
          severity: 'error',
          msg: 'Erreur survenue: ' + error.message,
        });
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    } else {
      disableAffectationMode();
    }
  };

  const handleCloseSnackbar = function () {
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = function (feedback) {
    setFeedback(feedback);
    setOpenSnackbar(true);
  };

  const showRegion = function () {
    mapRef.current.showRegion();
  };

  const showFrance = function () {
    setSelectedRegion(null);
    setSelectedSociete(null);
    setSocietes([]);

    setSelectedChargeDev(null);
    setChargeDevList([]);

    setAffectationMode(false);
    setCurrentAffectaions([]);

    mapRef.current.showFrance();
  };

  const handleClickOpenDialog = () => {
    let remplacants = chargeDevList.filter((cd) => cd.idIndividu !== selectedChargeDev.idIndividu);
    if (remplacants.length === 0)
      return handleOpenSnackbar({
        severity: 'warning',
        msg: 'Aucun autre chargé de dev trouvé pour le remplacement',
      });
    else setOpenDialog(true);
  };

  const handleCloseDialog = async (newChargeDev) => {
    setOpenDialog(false);
    disableAffectationMode();

    if (newChargeDev) {
      setLoading(true);

      try {
        const res = await service.saveRemplacementChargeDev(selectedSociete.refSiFinance, selectedChargeDev.idIndividu, newChargeDev);

        if (!res.ok) {
          throw new Error(res.status);
        }

        await showSocietesByRegion(selectedRegion.nom);
        handleOpenSnackbar({ severity: 'success', msg: 'ADD new OK' });
      } catch (error) {
        handleOpenSnackbar({
          severity: 'error',
          msg: 'Erreur survenue: ' + error.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseDialogAddCD = async (newChargeDev) => {
    setShowDialogAddCD(false);
    disableAffectationMode();

    if (newChargeDev) {
      setLoading(true);
      try {
        const res = await service.saveNewChargeDev(newChargeDev.idIndividu, selectedSociete.refSiFinance, metier);

        if (!res.ok) {
          throw new Error(res.status);
        }

        setChargeDevList((prev) => [...prev, newChargeDev]);

        handleOpenSnackbar({ severity: 'success', msg: 'ADD CD OK' });
      } catch (error) {
        handleOpenSnackbar({
          severity: 'error',
          msg: 'Erreur survenue: ' + error.message,
        });
      } finally {
        setLoading(false);
      }
      setLoading(false);
    }
  };

  const handleRemoveManager = async () => {
    setShowAlertDialog(false);

    try {
      setLoading(true);
      const res = await service.removeChargeDev(selectedChargeDev.idIndividu, selectedSociete.refSiFinance, metier);

      if (!res.ok) {
        throw new Error(res.status);
      }

      setChargeDevList((prev) => prev.filter((cd) => cd.idIndividu !== selectedChargeDev?.idIndividu));
      handleOpenSnackbar({ severity: 'success', msg: 'Remplacement OK' });
    } catch (error) {
      handleOpenSnackbar({
        severity: 'error',
        msg: 'Erreur survenue: ' + error.message,
      });
    } finally {
      setLoading(false);
      setSelectedChargeDev(null);
    }
  };

  const handleSelectedCD = (cd) => {
    setShowAlertDialog(true);
    setSelectedChargeDev(cd);
  };

  return (
    <div className="map-wrap">
      {selectedRegion?.nom && (
        <Card
          sx={{
            minWidth: 250,
            maxWidth: 400,
            position: 'absolute',
            zIndex: 1000,
            top: '10px',
            left: '10px',
          }}
        >
          <CardContent>
            {loading && (
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Chargement... <CircularProgress size={20} />
              </Typography>
            )}

            {!loading && (
              <>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="#" onClick={showFrance}>
                    France
                  </Link>
                  <Link underline="hover" color="text.primary" onClick={showRegion} href="#">
                    {selectedRegion.nom}
                  </Link>
                </Breadcrumbs>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {!loading ? societes.length + ' sociétés' : ''}
                </Typography>
              </>
            )}
            <Typography variant="h5" component="div">
              {selectedSociete?.denominationSociale}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {selectedSociete?.agences ? selectedSociete.agences.length + ' agences' : ''}
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body2">
              {selectedSociete?.adresseLigne1}
              <br />
              {selectedSociete?.codePostal} {selectedSociete?.ville}
            </Typography>

            {!loading && selectedSociete && !affectationMode && (
              <>
                <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" spacing={2}>
                  <Button size="small" variant="contained" color="gl" onClick={(e) => showChargeDevList('gl')}>
                    Chargé de dev GL
                  </Button>
                  <Button size="small" variant="contained" color="copro" onClick={(e) => showChargeDevList('copro')}>
                    Chargé de dev COPRO
                  </Button>
                </Stack>
                {canUpdateCD && metier && (
                  <Stack sx={{ mb: 2 }} direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Link onClick={() => setShowDialogAddCD(true)} href="#">
                      + Ajouter un CD
                    </Link>
                  </Stack>
                )}
              </>
            )}

            {!loading && !affectationMode && chargeDevList.length > 0 && (
              <Stack spacing={1}>
                {chargeDevList.map((cd, index) => (
                  <Box sx={{ display: 'flex' }} key={index}>
                    <Chip
                      sx={{
                        flex: 5,
                        backgroundColor: theme.palette[metier].main,
                        '&&:hover': {
                          backgroundColor: theme.palette[metier].dark,
                        },
                      }}
                      label={cd.nom + ' ' + cd.prenom}
                      onClick={(e) => handleSelectChargeDev(cd)}
                      variant="outlined"
                    />
                    <ClearIcon
                      onClick={() => handleSelectedCD(cd)}
                      sx={{
                        flex: 1,
                        color: 'red',
                        marginLeft: '0.5em',
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'darkred',
                          transform: 'scale(1.2)',
                          transition: '0.2s ease',
                        },
                      }}
                    />
                  </Box>
                ))}
              </Stack>
            )}

            {affectationMode && (
              <Paper
                sx={{
                  mb: 2,
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <TableContainer sx={{ maxHeight: '300px' }}>
                  <Table size="small" stickyHeader>
                    {_.isEmpty(currentAffectations) && <caption>Aucune affectation</caption>}
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: theme.palette[metier].main,
                          }}
                        >
                          Secteurs {metier.toUpperCase()} de {selectedChargeDev.prenom} {selectedChargeDev.nom}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentAffectations
                        .filter((aff) => aff.refIndividu && aff.refIndividu === selectedChargeDev.idIndividu)
                        .map((aff, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:nth-of-type(odd)': {
                                backgroundColor: theme.palette.action.hover,
                              },
                            }}
                          >
                            <TableCell scope="row">{aff.nom}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}

            {!loading && selectedChargeDev && affectationMode && (
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                {selectedSociete.cdAffectable && (
                  <>
                    <Button size="small" variant="contained" color={metier} onClick={handleSauvegarder}>
                      Sauvegarder
                    </Button>
                    <Button size="small" variant="contained" color={metier} onClick={handleClickOpenDialog}>
                      Remplacer
                    </Button>
                  </>
                )}
                <Button size="small" variant="contained" color={metier} onClick={disableAffectationMode}>
                  {selectedSociete.cdAffectable ? 'Annuler' : 'Retour'}
                </Button>
              </Stack>
            )}
          </CardContent>
        </Card>
      )}

      <MapSecteurChargeDev
        ref={mapRef}
        osmUrls={appConfig.app.osmUrls}
        selectedRegion={selectedRegion}
        selectedSociete={selectedSociete}
        selectedChargeDev={selectedChargeDev}
        handleSelectRegion={handleSelectRegion}
        handleSelectSociete={handleSelectSociete}
        handleSelectCommune={handleSelectCommune}
        handleRemoveCommune={handleRemoveCommune}
        handleOpenSnackbar={handleOpenSnackbar}
        theme={theme}
      ></MapSecteurChargeDev>

      <SnackbarFeedback openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} feedback={feedback} />

      {!loading && selectedChargeDev && affectationMode && selectedSociete.cdAffectable && (
        <RemplacerCdDialog open={openDialog} onClose={handleCloseDialog} chargeDevList={chargeDevList} currentChargeDev={selectedChargeDev} />
      )}

      {showDialogAddCD && (
        <RemplacerManagerDialog open={showDialogAddCD} onClose={handleCloseDialogAddCD} collabsEtablissement={[]} source="CD" metier={metier} />
      )}

      <ModalComponent title={'Confirmation'} open={showAlertDialog} setOpen={setShowAlertDialog} handleDelete={handleRemoveManager}>
        <DialogContentText>{'Êtes-vous sûr de vouloir supprimer le CD ?'}</DialogContentText>
      </ModalComponent>
    </div>
  );
}

export default SecteurChargeDev;
