import { Button,Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';


export default function ModalComponent(props) {
  const { open, setOpen, title, children, handleDelete } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={() => setOpen(false)} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title" sx={{ display: 'flex', alignItems: 'center' }}>
        <WarningRoundedIcon
          color="error"
          style={{
            marginRight: '0.5em',
          }}
        />
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => handleDelete()}>
          Oui
        </Button>

        <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
          Non
        </Button>
      </DialogActions>
    </Dialog>
  );
}
