import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import _ from 'lodash';
import SnackbarFeedback from '../components/SnackbarFeedback';
import { AppConfigContext } from '../context';
import BackendApiService from '../service/BackendApiService';

import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import MapSecteurDirectionVente from '../components/map/mapSecteurDirectionVente';
import RemplacerManagerDialog from './RemplacerManagerDialog';
import { buildSecteurAffecteEtab } from '../service/regions_transac';
import { ADMIN, DIRECTEUR_COMMERCIAL_TRANSACTION, TRANSACTION } from '../Constantes';
import useUserInfo from '../hooks/useUserInfo';

function SecteurDirectionVente() {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();

  const theme = useTheme();

  const [selectedEtablissement, setSelectedEtablissement] = useState(null);
  const [copySelectedEtablissement, setCopySelectedEtablissement] =
    useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [etablissements, setEtablissements] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [canUpdateManagerTransac, setCanUpdateManagerTransac] = useState(false);
  const [collabsSelectedEtablissement, setCollabsSelectedEtablissement] =
    useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [affectationMode, setAffectationMode] = useState(false);
  const [selectedAffectationZones, setSelectedAffectationZones] = useState([]);
  
  const { userInfo, userInfoLoading } = useUserInfo();

  const mapRef = useRef();

  const metier = 'tr';

  const service = useMemo(
    () => new BackendApiService(appConfig.app.backendApiUrl, authState),
    [appConfig.app.backendApiUrl, authState]
  );

  useEffect(() => {
    setLoading(userInfoLoading);
}, [userInfoLoading]);


  const handleSelectEtablissement = (selectedEtab) => {
    setSelectedEtablissement(_.cloneDeep(selectedEtab))
    setCopySelectedEtablissement(_.cloneDeep(selectedEtab))
  }

  const showAgencesByZone = async function (zoneDCId) {
    setLoading(true);

    try {
        const res = await service.fetchAgencesTransac(zoneDCId);

        if (!res.ok) {
            const errorStatus = res.status;
            const errorText = await res.text();
            throw new Error(errorStatus + ' ' + errorText);
        }

        let data = await res.json();
        data = data.filter((etab) => etab.latitude && etab.longitude);
        setEtablissements(data);
        mapRef.current.showEtablissements(data);

    } catch (error) {
        handleOpenSnackbar({
            severity: 'error',
            msg: 'Erreur survenue: ' + error.message,
        });
    } finally {
        setLoading(false);
    }
};


  const handleSelectZone = async (zone) => {
    setLoading(true);
    setSelectedZone(zone);
    await showAgencesByZone(zone.id);
  };

  const onSelectedCommune = (zone, codeCommune) => {
    const found = selectedAffectationZones.find(
      (item) => item.code === codeCommune
    );
    if (!found) {
      setSelectedAffectationZones([...selectedAffectationZones, zone]);
    } else {
      const newAffectationsZones = selectedAffectationZones.filter(
        (item) => item.code !== codeCommune
      );
      setSelectedAffectationZones(newAffectationsZones);
    }
  };

  const handleSelectCommune = function (commune) {
    let secteurs = [...selectedEtablissement[metier], { ...commune }];
    onSelectedCommune(commune, commune.code);
    setSelectedEtablissement({...selectedEtablissement, [metier]:secteurs});
  };

  const handleRemoveCommune = function (codeCommune) {
    let secteurs = selectedEtablissement[metier];
    let affExistanteArr = _.remove(secteurs, {
      code: codeCommune,
    });

    if (affExistanteArr.length > 0) {
      const aff = affExistanteArr[0];
      onSelectedCommune(aff, codeCommune);
    }
  };

  const handleCloseSnackbar = function () {
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = function (feedback) {
    setFeedback(feedback);
    setOpenSnackbar(true);
  };

  const showZone = function () {
    mapRef.current.showZone();
  };

  const showFrance = function () {
    setSelectedZone(null);
    setSelectedEtablissement(null);

    mapRef.current.showFrance();
  };

  const handleClickOpenDialog = async () => {
    let response;
    try {
      setLoading(true);
      response = await service.fetchCollabs(selectedEtablissement.refSiFinance);
      const collabsOfAgenceReponse = await response.json();
      const collabsOfAgence = _.uniqBy(collabsOfAgenceReponse, 'idIndividu');
      const remplacants = collabsOfAgence.filter(
        (collab) =>
          collab.idIndividu !== selectedEtablissement.managerTransac?.idIndividu
      );
      setCollabsSelectedEtablissement(remplacants);
      if (remplacants.length === 0)
        return handleOpenSnackbar({
          severity: 'warning',
          msg: "Aucun autre collaborateur n'est rattaché à cette agence",
        });
      else setOpenDialog(true);
    } catch (error) {
      console.log(error);
      if (response.status === 401) {
        handleOpenSnackbar({
          severity: 'error',
          msg: 'Opération non autorisée',
        });
      } else if (response.status === 403) {
        handleOpenSnackbar({
          severity: 'error',
          msg: "Vous n'avez pas le droit pour effectuer cette opération",
        });
      } else if (response.status === 404) {
        handleOpenSnackbar({
          severity: 'error',
          msg: "Aucun collaborateur n'est rattaché à cette agence",
        });
      } else {
        handleOpenSnackbar({
          severity: 'error',
          msg: 'Erreur survenue: ' + error.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleCloseDialog = async (newManagerTransac) => {
    setOpenDialog(false);
    disableAffectationMode();
    setCollabsSelectedEtablissement(null);
    if (newManagerTransac) {
      setLoading(true);
      try {
        const response = await service.saveManagerTransac(
          selectedEtablissement.refSiFinance,
          newManagerTransac.idIndividu
        );
        if (!response.ok) throw new Error(response.status);

        const newSelectedEtab = {
          ...selectedEtablissement,
          managerTransac: newManagerTransac,
        };
        setSelectedEtablissement(newSelectedEtab);
        const newEtablissementsList = etablissements.map((etablissement) =>
          etablissement.refSiFinance === newSelectedEtab.refSiFinance
            ? newSelectedEtab
            : etablissement
        );
        setEtablissements(newEtablissementsList);
        mapRef.current.showEtablissements(newEtablissementsList);
        handleOpenSnackbar({
          severity: 'success',
          msg: 'Nomination du directeur de vente est effectuée avec succès',
        });
      } catch (error) {
        console.log(error);
        handleOpenSnackbar({
          severity: 'error',
          msg: 'Erreur lors du désignation du directeur de vente',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRemoveManagerTransac = async () => {
    setLoading(true);
    try {
      const response = await service.removeManagerTransac(
        selectedEtablissement.refSiFinance
      );
      if (!response.ok) throw new Error(response.status);
      const newSelectedEtab = {
        ...selectedEtablissement,
        managerTransac: null,
      };
      setSelectedEtablissement(newSelectedEtab);
      const newEtablissementsList = etablissements.map((etablissement) =>
        etablissement.refSiFinance === newSelectedEtab.refSiFinance
          ? newSelectedEtab
          : etablissement
      );
      setEtablissements(newEtablissementsList);
      mapRef.current.showEtablissements(newEtablissementsList);
      handleOpenSnackbar({
        severity: 'success',
        msg: 'Le directeur de vente a été retiré avec succès',
      });
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({
        severity: 'error',
        msg: 'Erreur lors de la suppression du directeur de vente',
      });
    } finally {
      setLoading(false);
    }
  };

  const disableAffectationMode = function () {
    setAffectationMode(false);
    mapRef.current.disableAffectationMode();
  };

  const defineSecteurs = function () {
    setAffectationMode(true);
    // communes partitions
    let allSecteurEtab = buildSecteurAffecteEtab(etablissements, metier);

    mapRef.current.enableAffectationMode(_.cloneDeep(allSecteurEtab), metier);
  };

  const handleSauvegarder = async function (e) {
    setLoading(true);

    try {
        const res = await service.saveAffectationsEtablissementDV(selectedEtablissement, metier, selectedAffectationZones);

        if (!res.ok) {
            const errorStatus = res.status;
            const errorText = await res.text();
            throw new Error(errorStatus + ' ' + errorText);
        }

        disableAffectationMode();

        await showAgencesByZone(selectedZone.id);
        handleOpenSnackbar({ severity: 'success', msg: 'Sauvegarde OK' });
        setSelectedEtablissement(null);
        setSelectedAffectationZones([])
    } catch (error) {
        setFeedback({
            severity: 'error',
            msg: 'Erreur survenue: ' + error.message,
        });
        setOpenSnackbar(true);
    } finally {
        setLoading(false);
    }
};

  useEffect(() => {
    const profil = userInfo?.profil;
    const zoneDvAccess = userInfo?.access.reduce(
      (acc, val) => [...acc, val.zoneDV],
      []
    );
    const isAuthorizedToUpdateManagerTransac =
      profil === ADMIN ||
      (profil === DIRECTEUR_COMMERCIAL_TRANSACTION &&
        zoneDvAccess?.includes(selectedEtablissement?.zoneDV));

    setCanUpdateManagerTransac(isAuthorizedToUpdateManagerTransac);
  }, [selectedEtablissement, userInfo]);
  if (!authState || !authState.isAuthenticated) return null;

  const handleRetrunButton = () => {
    setSelectedEtablissement(_.cloneDeep(copySelectedEtablissement));
    disableAffectationMode();
  };

  return (
    <div className='map-wrap'>
      {selectedZone?.nom && (
        <Card
          sx={{
            minWidth: 250,
            maxWidth: 400,
            position: 'absolute',
            zIndex: 1000,
            top: '10px',
            left: '10px',
          }}
        >
          <CardContent>
            {loading && (
              <Typography
                sx={{ fontSize: 14 }}
                color='text.secondary'
                gutterBottom
              >
                Chargement... <CircularProgress size={20} />
              </Typography>
            )}

            {!loading && (
              <>
                <Breadcrumbs aria-label='breadcrumb'>
                  <Link
                    underline='hover'
                    color='inherit'
                    href='#'
                    onClick={showFrance}
                  >
                    France
                  </Link>
                  <Link
                    underline='hover'
                    color='text.primary'
                    onClick={showZone}
                    href='#'
                  >
                    {selectedZone.nom}
                  </Link>
                </Breadcrumbs>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                  {!loading ? etablissements.length + ' agences' : ''}
                </Typography>
              </>
            )}

            <Typography variant='h5' component='div'>
              {selectedEtablissement?.nomUsage}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color='text.secondary'>
              {selectedEtablissement?.denominationSociale}
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant='body2'>
              {selectedEtablissement?.refSiFinance}
              <br />
              {selectedEtablissement?.adresseLigne1}
              <br />
              {selectedEtablissement?.codePostal} {selectedEtablissement?.ville}
            </Typography>
            <Typography sx={{ mb: 2 }} variant='body2'>
              {selectedEtablissement && <strong> Directeur de vente : </strong>}{' '}
              {selectedEtablissement ? (
                canUpdateManagerTransac ? (
                  selectedEtablissement?.managerTransac ? (
                    <>
                      <Link onClick={handleClickOpenDialog} href='#'>
                        {selectedEtablissement?.managerTransac.nom}{' '}
                        {selectedEtablissement?.managerTransac.prenom}
                      </Link>
                      <ClearIcon
                        onClick={handleRemoveManagerTransac}
                        style={{
                          color: 'red',
                          verticalAlign: 'middle',
                          marginLeft: '0.5em',
                        }}
                      />
                    </>
                  ) : (
                    <Link onClick={handleClickOpenDialog} href='#'>
                      + Ajouter
                    </Link>
                  )
                ) : selectedEtablissement?.managerTransac ? (
                  <Typography sx={{ mb: 1.5 }} variant='body2' component='span'>
                    {selectedEtablissement?.managerTransac.nom}{' '}
                    {selectedEtablissement?.managerTransac.prenom}
                  </Typography>
                ) : (
                  <Typography sx={{ mb: 1.5 }} variant='body2' component='span'>
                    Non nommé
                  </Typography>
                )
              ) : (
                ''
              )}
            </Typography>
            {selectedEtablissement && selectedEtablissement[metier] && (
              <Paper sx={{ mb: 2, width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: '300px' }}>
                  <Table size='small' stickyHeader>
                    {_.isEmpty(selectedEtablissement[metier]) && (
                      <caption>Aucun secteur affecté</caption>
                    )}
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ backgroundColor: theme.palette[metier].main }}
                        >
                          Secteurs {metier.toUpperCase()}
                        </TableCell>
                        <TableCell
                          sx={{ backgroundColor: theme.palette[metier].main }}
                          align='right'
                        >
                          Code postal
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedEtablissement[metier].map((c) => (
                        <TableRow
                          key={c.code}
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: theme.palette.action.hover,
                            },
                          }}
                        >
                          <TableCell component='th' scope='row'>
                            {c.nom}
                          </TableCell>
                          <TableCell align='right'>
                            {c.codesPostaux.join(',')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}

            {!loading && selectedEtablissement && !affectationMode && (
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <Button
                  size='small'
                  variant='contained'
                  color='tr'
                  onClick={(e) => defineSecteurs()}
                >
                  Secteurs TR
                </Button>
              </Stack>
            )}

            {!loading && selectedEtablissement && affectationMode && (
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                {selectedEtablissement.secteurAffectable && (
                  <Button
                    size='small'
                    variant='contained'
                    color={metier}
                    onClick={handleSauvegarder}
                  >
                    Sauvegarder
                  </Button>
                )}
                <Button
                  size='small'
                  variant='contained'
                  color={metier}
                  onClick={handleRetrunButton}
                >
                  {selectedEtablissement.secteurAffectable
                    ? 'Annuler'
                    : 'Retour'}
                </Button>
              </Stack>
            )}
          </CardContent>
        </Card>
      )}

      <MapSecteurDirectionVente
        ref={mapRef}
        osmUrls={appConfig.app.osmUrls}
        selectedZone={selectedZone}
        selectedEtablissement={selectedEtablissement}
        handleSelectZone={handleSelectZone}
        handleSelectEtablissement={handleSelectEtablissement}
        handleSelectCommune={handleSelectCommune}
        handleRemoveCommune={handleRemoveCommune}
        handleOpenSnackbar={handleOpenSnackbar}
        theme={theme}
      ></MapSecteurDirectionVente>

      <SnackbarFeedback
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        feedback={feedback}
      />

      {!loading &&
        selectedEtablissement &&
        collabsSelectedEtablissement &&
        canUpdateManagerTransac && (
          <RemplacerManagerDialog
            open={openDialog}
            onClose={handleCloseDialog}
            collabsEtablissement={collabsSelectedEtablissement}
            source={TRANSACTION}
          />
        )}
    </div>
  );
}

export default SecteurDirectionVente;
